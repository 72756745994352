import './App.css';

function App() {
	return (
		<div>
			<div className="page-title">
				idontthinkicandothisanymore
			</div>
			<div className="post-time">
				time
			</div>
			<div className="post-content">
				content
			</div>
		</div>
	);
}

export default App;
